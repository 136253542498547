<template>
  <div class="index-down-btn-container">
    <div @click="handClick(item.hostLink)" class="btn" v-for="item in hostLinks" :key="item.id">
      <Img
        :width="'23.5rem'"
        :height="'6.8rem'"
        :url="item.btn +'-' + $i18n.locale +'.png'"
        :alt="$t(item.describe)"
      />
    </div>
  </div>
</template>
    
<script>
// import { downloadHostLink } from "@/api/hhpoker/download";
import Img from "@/components/Image";

export default {
  name: "IndexDownBtn",
  data() {
    return {
      hostLinks: [],
    };
  },
  components: {
    Img,
  },
  created() {
    this.hostLinks = this.downloadHostLink;
  },
  methods: {
    handClick(link) {
      window.open(link);
    },
  },
};
</script>
    
<style lang="less" scoped>
@import "~@/assets/mixin.less";
.index-down-btn-container {
  .flex-center(center);
  .self-fill(absolute);
  height: auto;
  z-index: 2;
  top: auto;
  bottom: 7.2rem;
  .btn {
    margin: 0 2rem;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>