<template>
  <div
    class="site-header-container"
    :class="[skin(),{'mobile':this.$device.ios||this.$device.android }]"
  >
    <div class="logo">
      <Img :url="this.logo.logo + '.png'" />
    </div>
    <Menu />
  </div>
</template>

<script>
import Menu from "./Menu";
import Img from "@/components/Image";
export default {
  name: "siteHeader",
  components: {
    Menu,
    Img,
  },
  methods: {
    skin() {
      return process.env.VUE_APP_POKER;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.site-header-container {
  .flex-center(space-between);
  width: 100%;
  height: 8rem;
  padding: 0 4rem;
  background: @dark;
  .logo {
    height: 2.8rem;
    width: 14.8rem;
  }
}
.mobile {
  height: 20rem;
}
</style>