<template>
  <div class="carouselitem-container swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide swiper-slide1" v-for="banner in bannerItems" :key="banner.id">
        <Img
          :height="'95rem'"
          :url="banner.introduceItem +'-' + $i18n.locale +'.png'"
          :alt="$t(banner.describe)"
        />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>

    <IndexDownBtn v-if="!this.$device.ios && !this.$device.android" />
  </div>
</template>
  
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import Img from "@/components/Image";
import IndexDownBtn from "@/components/IndexDownBtn";

import $ from "jquery";

$(function () {
  new Swiper(".swiper-container", {
    // config...
    // loop: true,
    autoplay: {
      delay: 4000, // 每次轮播间隔的ms数，默认3000ms
      stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
      disableOnInteraction: false, // 用户操作轮播图后，比如点击轮播按钮或小圆点，停止轮播
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "点击可进入下一页幻灯片",
      firstSlideMessage: "This is the first slide",
      lastSlideMessage: "This is the last slide",
      paginationBulletMessage: "Go to slide {{index}}",
      notificationClass: "swiper-notification",
      containerMessage: "banner",
      containerRoleDescriptionMessage: "This is a swiper", //aria-role-description
      itemRoleDescriptionMessage: "slider",
      slideLabelMessage: "{{index}} / {{slidesLength}}",
      slideRole: "group",
    },
  });

  // for (var i = 0; i < mySwiper.pagination.bullets.length; i++) {
  //   mySwiper.pagination.bullets[i].onmouseover = function () {
  //     this.click();
  //   };
  // }
  // //如果你在swiper初始化后才决定使用clickable，可以这样设置
  // mySwiper.params.pagination.clickable = true;
  // //此外还需要重新初始化pagination
  // mySwiper.pagination.destroy()
  // mySwiper.pagination.init()
  // mySwiper.pagination.bullets.eq(0).addClass('swiper-pagination-bullet-active');
});

export default {
  components: {
    Img,
    IndexDownBtn,
  },
  name: "CarouseLitem",
  data() {
    return {
      // imgName1: "banner1",
      // imgName2: "banner2",
      bannerItems: this.bannerItems,
    };
  },
  created() {
    // console.log(this.bannerItems);
  },
  updated() {},
  mounted() {
    // window.onresize = () => {
    //   console.log(this.$device);
    // };
  },
  methods: {
    // test() {},
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  --swiper-theme-color: rgba(255, 255, 255, 0.3); /* 设置Swiper风格 */
  // --swiper-navigation-color: #f9e9d4; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 3rem; /* 设置按钮大小 */
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  line-height: 100%;
  // color: #222429;
}
</style>