import Home from "@/views/Home/Index";
import Download from "@/views/Download/Index";

export default [
  {
    name: "Home",
    path: "/",
    edirect: '/Home',
    component: Home,
    // meta:{
    //   langKey: 'nav.system.management',
    // }
  },
  {
    name: "Download",
    path: "/Download",
    edirect: '/Download',
    component: Download,
    // meta: {
    //   langKey: 'nav.system.management',
    //   hideOnMobile: true,
    // }
  },
];
