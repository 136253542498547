<template>
  <div class="index-container">
    <div class="indexdownload wrap" v-if="this.$device.ios">
      <div
        @click="clickDownload(downloadIosLink.url)"
        v-for="downloadIosLink in downloadIosLinks"
        :key="downloadIosLink.id"
      >
        <Img
          v-if="downloadIosLink.flag"
          class="flag"
          :width="'13rem'"
          :height="'13rem'"
          :url="downloadIosLink.flag +'-' + $i18n.locale +'.png'"
        />
        <Img
          :width="'78rem'"
          :url="downloadIosLink.iosBtnImg +'-' + $i18n.locale +'.png'"
          :alt="$t(downloadIosLink.describe)"
        />
      </div>
    </div>
    <div class="indexdownload wrap" v-if="this.$device.android">
      <div
        @click="clickDownload(downloadAndroidLink.url)"
        v-for="downloadAndroidLink in downloadAndroidLinks"
        :key="downloadAndroidLink.id"
      >
        <Img
          v-if="downloadAndroidLink.flag"
          class="flag"
          :width="'13rem'"
          :height="'13rem'"
          :url="downloadAndroidLink.flag +'-' + $i18n.locale +'.png'"
        />
        <Img
          :width="'78rem'"
          :url="downloadAndroidLink.androidBtnImg +'-' + $i18n.locale +'.png'"
          :alt="$t(downloadAndroidLink.describe)"
        />
      </div>
    </div>
    <div class="wrap" v-if="!this.$device.ios && !this.$device.android">
      <h2 class="container-tit">{{ $t('home.gameplayIntroduction') }}</h2>
      <div class="container-box">
        <div class="videoItem" v-for="videoItem in videoItems" :key="videoItem.id">
          <VideoPlayer ref="vueRef" :videoDetal="videoItem" />
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="introduce-item" v-for="introduceItem in introduceItems" :key="introduceItem.id">
        <Img
          :height="'52rem'"
          :url="introduceItem.introduceItem +'-' + $i18n.locale +'.png'"
          :alt="$t(introduceItem.describe)"
        />
      </div>
    </div>
    <div class="wrap">
      <div class="contact-container">
        <div class="title">{{ $t('home.contactUs')}}</div>
        <div class="container">
          <div class="contact-item" v-for="contactItem in contactItems" :key="contactItem.id">
            <ContactItem :width="'6rem'" :contactItem="contactItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Image";
import ContactItem from "@/components/ContactItem";
import VideoPlayer from "@/components/Videoplayer";
// import { downloadIos, downloadAndroid } from "@/api/hhpoker/download";
// import { introduceItems } from "@/api/hhpoker/indexImg";
// import { contactItems } from "@/api/hhpoker/contact";
// import { videoItems } from "@/api/hhpoker/video";
// import video1 from "@/assets/video/video1.mp4";
// import video2 from "@/assets/video/video2.mp4";
// import video3 from "@/assets/video/video3.mp4";
// import video4 from "@/assets/video/video4.mp4";

export default {
  name: "HomeIndex",
  components: {
    VideoPlayer,
    Img,
    ContactItem,
  },
  data() {
    return {
      downloadIosLinks: this.downloadIos,
      downloadAndroidLinks: this.downloadAndroid,
      introduceItems: this.introduceItems,
      contactItems: this.contactItems,
      videoItems: this.videoItems,
    };
  },
  methods: {
    // handleClick(item) {
    //   console.log(item.state)
    // }
    clickDownload(link) {
      window.open(link);
      // console.log(link);
    },
  },
  created() {
    // console.log(this.videoItems, "---------");
    // this.downloadIosLinks = downloadIos();
    // this.downloadAndroidLinks = downloadAndroid();
    // this.introduceItems = introduceItems();
    // this.contactItems = contactItems();
    // this.videoItems = videoItems();
  },
};
</script>

<style lang="less"  scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";

.index-container {
  margin-top: 8rem;
  .container-tit {
    text-align: center;
    font-size: 3.2rem;
  }
}
.index-container .container-box {
  display: flex;
  justify-content: center;
  margin: 4rem 0 0 -4rem;
}

.videoItem {
  width: 29rem;
  height: 29rem;
  margin-left: 4rem;
}

.indexdownload {
  // margin: 0 4rem;
  .flex-center(center);
  flex-flow: wrap;
  & > div {
    position: relative;
    cursor: pointer;
    .flex-center(center);
    width: 50%;
    height: 22rem;
    margin: -1px -1px 0 0;
    border: 1px solid darken(@lightWords, 80%);
    .flag {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.introduce-item {
  margin-top: 8rem;
  font-size: 0;
  border-radius: 2rem;
  &:hover {
    box-shadow: 0 2rem 4rem (rgba(0, 0, 0, 0.5));
  }
}

.contact-container {
  margin-top: 8rem;
  .title {
    // position: relative;
    .flex-center();
    font-size: @fontSize32;
    text-align: center;
    &:before,
    &:after {
      content: "";
      display: block;
      height: 1px;
      flex: 1;
      background: darken(@lightWords, 80%);
    }
    &:after {
      margin-left: 2rem;
    }
    &:before {
      margin-right: 2rem;
    }
  }
}

// 联系我们
.container {
  .flex-center();
  .contact-item {
    flex: 1;
    text-align: center;
    padding: 6rem 0;
  }
}
</style>