<template>
  <img class="img-container" :src="url" :style="{ height: height , width: width }" :alt="alt" />
</template>
  
<script>
export default {
  name: "imgContainer",
  // props可以传对象，也可以传数组
  props: {
    url: {
      // 必须是字符串类型
      type: String,
      // 必须传参数
      required: false,
    },
    alt: {
      type: String,
      default: "poker",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
      // default: 150,
    },
  },
};
</script>


<style lang="less" scoped>
.img-container {
  display: inline-block;
  object-fit: cover;
  overflow: hidden;
}
img {
  outline: none;
}
</style>