<template>
  <div class="tutorials-container">
    <h3 class="title">{{ $t(commonTutorials.step) }}</h3>
    <Img
      class="img"
      :width="width"
      :url="commonTutorials.img + '-' + $i18n.locale  +'.png'"
      :alt="$t(commonTutorials.describe)"
    />
  </div>
</template>

<script>
import Img from "@/components/Image";
export default {
  components: {
    Img,
  },
  props: ["commonTutorials", "width"],
  created() {
    // console.log("=====");
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.tutorials-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .title {
    // height: 20rem;
    flex: 1;
    font-size: @fontSize32;
    // margin-bottom: 8rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .img {
    height: auto !important;
    object-fit: contain;
  }
}
</style>