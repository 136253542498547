export function bannerItems() {
  return [
    {
      id: "1",
      introduceItem: '../../hhpoker/banner1',
      describe: 'banner.hostBanner1'
    },
    {
      id: "2",
      introduceItem: '../../hhpoker/banner2',
      describe: 'banner.hostBanner2'
    },
  ]
}

export function introduceItems() {
  return [
    {
      id: "1",
      introduceItem: '../../hhpoker/index-introduce1',
      describe: 'banner.introduce1'
    },
    {
      id: "2",
      introduceItem: '../../hhpoker/index-introduce2',
      describe: 'banner.introduce2'
    },
    {
      id: "3",
      introduceItem: '../../hhpoker/index-introduce3',
      describe: 'banner.introduce3'
    },
  ]
}

export function logo() {
  return {
    logo: '../../hhpoker/logo',
  }
}

export function footLogo() {
  return {
    logo: '../../hhpoker/foot-logo',
  }
}

export function videoItems() {
  return [
    {
      id: "1",
      img: '../../hhpoker/video-entry1',
      video: '../../hhpoker/video/video1',
      describe: 'gameVideo.gamePlay1'
    }, {
      id: "2",
      img: '../../hhpoker/video-entry2',
      video: '../../hhpoker/video/video2',
      describe: 'gameVideo.gamePlay2'
    }, {
      id: "3",
      img: '../../hhpoker/video-entry3',
      video: '../../hhpoker/video/video3',
      describe: 'gameVideo.gamePlay3'
    }, {
      id: "4",
      img: '../../hhpoker/video-entry4',
      video: '../../hhpoker/video/video4',
      describe: 'gameVideo.gamePlay4'
    },
  ]
}
