import Vue from 'vue'
import App from './App.vue'
import "./assets/reset.css"
import "./assets/global.less"
import router from '@/router/index.js'
import i18n from '@/lang/hhpoker'
import device from 'vue-device-detector'
import { isMobile } from '@/units/isMobile'
import skinHhpoker from './skin/hhpoker'


Vue.use(device)

new Vue({
  router,
  i18n,
  device,
  data() {
    return skinHhpoker.data()
  },
  created() {
    skinHhpoker.created()
    document.querySelector('html').classList.add(process.env.VUE_APP_POKER)
    if (isMobile()) {
      document.querySelector('html').classList.add('mobile')
    } else {
      document.querySelector('html').classList.add('pc')
    }
  },
  render: h => h(App),
}).$mount('#app')

