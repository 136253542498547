export function iosTutorials() {
    return [
        {
            id: "1",
            step: 'download.iosTepts1',
            img: "../../hhpoker/ios-tutorials-step1",
            describe: "download.iosTepts1"
        },
        {
            id: "2",
            step: "download.iosTepts2",
            img: "../../hhpoker/ios-tutorials-step2",
            describe: "download.iosTepts2"
        },
        {
            id: "3",
            step: "download.iosTepts3",
            img: "../../hhpoker/ios-tutorials-step3",
            describe: "download.iosTepts3"
        },
        // {
        //     id: "4",
        //     step: "download.iosTepts4",
        //     img: "../../hhpoker/ios-tutorials-step4"
        // },
    ]
}

export function androidTutorials() {
    return [
        {
            id: "1",
            step: 'download.iosTepts1',
            img: "../../hhpoker/android-tutorials-step1",
            describe: "download.iosTepts1"
        },
        {
            id: "2",
            step: "download.androidTepts2",
            img: "../../hhpoker/android-tutorials-step2",
            describe: "download.androidTepts2",
        },
        {
            id: "3",
            step: "download.androidTepts3",
            img: "../../hhpoker/android-tutorials-step3",
            describe: "download.androidTepts3"
        },
        {
            id: "4",
            step: "download.androidTepts4",
            img: "../../hhpoker/android-tutorials-step4",
            describe: "download.androidTepts4"
        },
        {
            id: "5",
            step: "download.androidTepts5",
            img: "../../hhpoker/android-tutorials-step5",
            describe: "download.androidTepts5"
        },
        {
            id: "6",
            step: "download.androidTepts6",
            img: "../../hhpoker/android-tutorials-step6",
            describe: "download.androidTepts6"
        },
        {
            id: "7",
            step: "download.androidTepts7",
            img: "../../hhpoker/android-tutorials-step7",
            describe: "download.androidTepts7"
        },
        {
            id: "8",
            step: "download.androidTepts8",
            img: "../../hhpoker/android-tutorials-step8",
            describe: "download.androidTepts8"
        },
    ]
}
