import Vue from 'vue'

import { contactItems } from "@/api/hhpoker/contact";
import { bannerItems, introduceItems, logo, footLogo, videoItems } from "@/api/hhpoker/index";
import { downloadIos, downloadAndroid, downloadHostLink } from "@/api/hhpoker/downloadApp";
import { iosTutorials, androidTutorials } from "@/api/hhpoker/download";

export default {
  data() {
    return {
      contactItems: [],
      introduceItems: [],
      downloadIos: [],
      downloadAndroid: [],
      videoItems: [],
      iosTutorials: [],
      androidTutorials: [],
      bannerItems: [],
      downloadHostLink: [],
      coperight: [],
      logo: []
    }
  },
  created() {
    Vue.prototype.contactItems = contactItems();
    Vue.prototype.introduceItems = introduceItems();
    Vue.prototype.downloadIos = downloadIos();
    Vue.prototype.downloadAndroid = downloadAndroid();
    Vue.prototype.videoItems = videoItems();
    Vue.prototype.iosTutorials = iosTutorials();
    Vue.prototype.androidTutorials = androidTutorials();
    Vue.prototype.bannerItems = bannerItems();
    Vue.prototype.downloadHostLink = downloadHostLink();
    Vue.prototype.footLogo = footLogo();
    Vue.prototype.logo = logo();
  },
}